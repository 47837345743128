.section {
  margin-bottom: 20px;
  > .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    .title {
      line-height: 1.2;
      position: relative;
      margin-bottom: 0;
      padding-left: 8px;

      &::before {
        content: ' ';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        display: block;
        background: #1890ff;
        border-radius: 2px 2px 2px 2px;
        width: 4px;
      }
    }
  }
  > .content {
    border-radius: 8px;
    padding: 20px;
  }
}
