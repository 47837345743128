.admin-portal {
  height: 100vh;

  .admin-portal-main-sider {
    background: #ffffff;
    border-right: 1px solid #99989898;

    .product-name {
      font-size: 24px;
      font-weight: 600;
      text-align: center;
      padding: 10px 0;
      font-family: '-webkit-pictograph', sans-serif;
    }
  }

  .admin-portal-main {
    .admin-portal-header {
      height: 50px;
      background: #ffffff;
      border-bottom: 1px solid #99989898;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0 20px;
    }

    .admin-portal-main-content {
      padding: 20px;
      overflow-y: scroll;
      overflow-y: overlay;
    }
  }
}
