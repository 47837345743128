.box1 {
  height: 180px;
  border-radius: 8px 8px 8px 8px;
  box-shadow: 0px 0px 20px 0px rgba(57, 113, 228, 0.2);
  box-sizing: border-box;
  padding: 20px 30px;
  background-color: #fff;
}

.tag1 {
  font-size: 16px;
  padding: 10px 20px;
  background: linear-gradient(90deg, #c9ebe9 0%, rgba(255, 255, 255, 0) 100%);
  display: inline-block;
}
