.chat {
  display: flex;
  flex-direction: column;
  background-color: #f5f6fa;
  box-sizing: border-box;
  padding: 20px;
  min-height: 300px;

  .chat-body {
    overflow: auto;
    overflow-x: hidden;
    padding: 20px 0 40px;
    position: relative;
    overscroll-behavior: none;
  }

  .chat-input {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    border-radius: 4px;
    position: relative;
    background-color: #fff;

    > textarea {
      width: 100%;
      resize: none;
      background-color: transparent;
      border: 0;
      padding: 10px 6px;
      box-sizing: border-box;
      padding-right: 38px;
    }

    .btn-send {
      position: absolute;
      right: 6px;
      top: 6px;
    }

    .btn-chose {
      position: absolute;
      left: 6px;
      top: 6px;
    }
  }

  .chat-message {
    display: flex;
    margin: 10px 0;

    &.user {
      flex-direction: row-reverse;

      .inner {
        flex-direction: row-reverse;
      }
      .avatar {
        margin-left: 10px;
        margin-right: 0;
      }
      .content {
        border-radius: 16px 16px 0px 16px;
        color: #fff;
        background-color: #6673e7;
      }
    }

    .inner {
      display: flex;
      max-width: 80%;
    }

    .avatar {
      // width: 32px;
      // height: 32px;
      // border-radius: 8px;
      // background-color: #c8d6f1;
      margin-right: 10px;
    }
    .content {
      border-radius: 10px;
      font-size: 14px;
      background-color: #fff;
      user-select: text;
      padding: 10px;
      word-break: break-all;
      transition: all 0.3s ease;
      box-sizing: border-box;
      border-radius: 16px 16px 16px 0px;
      color: #1e2f50;
    }
  }
}
